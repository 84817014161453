import React from 'react'

function Code() {

    document.title = "Soil Code"

    return (
        <div className='unavailable-text'>
            <div className="text">Coming soon!</div>
        </div>
    )
}

export default Code